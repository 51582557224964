import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    hr: {
        translation: {
            links_title: 'POVEZNICE',
            links_requests: 'ZAHTJEVI',
            links_documents: 'DOKUMENTI',
            links_processes: 'PROCESI',
            links_identification: 'POUZDANOST IDENTIFIKACIJE',
            account_logout: 'ODJAVA',
            account_mode_one: 'Tamna tema je UKLJUČENA',
            account_mode_two: 'Tamna tema je ISKLJUČENA',
            language_hr: 'Hrvatski',
            language_en: 'Engleski',
            requests_from: 'Od:',
            requests_to: 'Do:',
            requests_status_all: 'SVI',
            requests_status_initiated: 'ZAPOČETI',
            requests_status_completed: 'ZAVRŠENI',
            requests_status_rejected: 'ODBIJENI',
            requests_merchant: 'TRGOVAC',
            requests_search: 'PRETRAŽI',
            requests_grid_created: 'Kreirano',
            requests_grid_name: 'Ime',
            requests_grid_family: 'Prezime',
            requests_grid_amount: 'Iznos',
            requests_grid_merchant: 'Trgovac',
            grid_rowsperpage: 'Redova po stranici:',
            back_button: 'Natrag',
            orderinfo_clientinfo: 'Informacije o klijentu',
            orderinfo_fullname: 'Ime i prezime',
            orderinfo_email: 'E-mail adresa',
            orderinfo_phone: 'Broj telefona',
            orderinfo_city: 'Grad',
            orderinfo_address: 'Adresa',
            orderinfo_idnumber: 'Broj osobne iskaznice',
            orderinfo_dateofexpiry: 'Datum isteka osobne iskaznice',
            orderinfo_requestinfo: 'Informacije o zahtjevu',
            orderinfo_requestpaymenttype: 'Način plaćanja',
            consents_newtype: 'Novi tip dokumenta',
            consents_activefilter: 'Prikaži neaktivne',
            consents_doctype: 'Tip dokumenta',
            consents_updated: 'Ažurirano',
            consents_enabled: 'Aktivno',
            consents_required: 'Obavezno',
            edit_button: 'UREDI',
            details_button: 'DETALJI',
            lastedited: 'Posljednje uređivano:',
            active: 'Aktivno',
            activate: 'Aktiviraj',
            required: 'Obavezno',
            addnewdoc: 'Dodaj novi dokument',
            dragndrop_text: 'Povuci datoteku ovdje ili klikni',
            dragndrop_tooLargeFile: 'Veličina datoteke je prevelika',
            dragndrop_notSupportedAttachment: 'Podržan je samo PDF tip datoteka',
            dragndrop_selectFile: 'Odaberi datoteku',
            dragndrop_noFilesAddedPrimary: 'Nije dodana nijedna datoteka',
            dragndrop_noFilesAddedSecondary: 'Možete dodati datoteku povlačenjem na polje iznad ili klikom na polje',
            please_try_again: 'Molimo pokušajte ponovno',
            send_button: 'Pošalji',
            sending: 'Slanje',
            last_change: 'Zadnja promjena',
            consents_addgroup: 'Dodaj novu grupu dokumenata',
            consents_uploaddoc: 'Učitaj novi dokument',
            name: 'Naziv',
            processes_erroronly: 'Prikaži samo greške',
            date: 'Datum',
            processes_personaldata: 'Osobni podaci',
            processes_docnumber: 'Broj dokumenta',
            processes_networkdata: 'Mrežni podaci',
            processes_ipaddress: 'IP adresa',
            processes_useragent: 'Korisnički agent',
            processes_dateofbirth: 'Datum rođenja',
            processes_sex: 'Spol',
            processes_nationalitycode: 'Kod nacionalnosti',
            processes_dateofissue: 'Datum izdavanja',
            processes_dateofexpiry: 'Datum isteka',
            processes_issuingcountrycode: 'Kod države izdavanja',
            tos_acceptedconsents: 'Prihvaćene suglasnosti:',
            pdfpage: 'od',
            psd2_currency: 'Valuta',
            psd2_displayname: 'Naziv za prikaz',
            psd2_cashacctype: 'Tip gotovinskog računa',
            psd2_usage: 'Upotreba',
            psd2_ownername: 'Vlasnik',
            psd2_balancetype: 'Tip salda',
            psd2_amount: 'Saldo',
            psd2_creditlimit: 'Kreditni limit',
            psd2_transactionid: 'ID transakcije',
            psd2_entryreference: 'Referenca unosa',
            psd2_bookingdate: 'Datum knjiženja',
            psd2_valuedate: 'Datum valute',
            psd2_transactionamount: 'Iznos transakcije',
            psd2_creditorname: 'Ime vjerovnika',
            psd2_creditoriban: 'IBAN vjerovnika',
            psd2_ultimatecreditor: 'Stvarni vjerovnik',
            psd2_debtorname: 'Ime dužnika',
            psd2_debtoriban: 'IBAN dužnika',
            psd2_ultimatedebtor: 'Stvarni dužnik',
            psd2_transactioninfo: 'Opis transakcije',
            psd2_additionalinfo: 'Dodatni opis transakcije',
            psd2_purposecode: 'Kod svrhe',
            psd2_sourcecurrency: 'Izvorna valuta',
            psd2_targetcurrency: 'Ciljna valuta',
            psd2_exchangerate: 'Tečaj',
            agent_face_match: 'Provjera lica - agent',
            face_match: 'Provjera lica',
            video_check_result: 'Rezultat video provjere',
        },
    },
    en: {
        translation: {
            links_title: 'LINKS',
            links_requests: 'REQUESTS',
            links_documents: 'DOCUMENTS',
            links_processes: 'PROCESSES',
            links_identification: 'IDENTIFICATION RELIABILITY',
            account_logout: 'LOGOUT',
            account_mode_one: 'Dark mode is ON',
            account_mode_two: 'Dark mode is OFF',
            language_hr: 'Croatian',
            language_en: 'English',
            requests_from: 'From:',
            requests_to: 'To:',
            requests_status_all: 'ALL',
            requests_status_initiated: 'INITIATED',
            requests_status_completed: 'COMPLETED',
            requests_status_rejected: 'REJECTED',
            requests_merchant: 'MERCHANT',
            requests_search: 'SEARCH',
            requests_grid_created: 'Created',
            requests_grid_name: 'Name',
            requests_grid_family: 'Last name',
            requests_grid_amount: 'Amount',
            requests_grid_merchant: 'Merchant',
            grid_rowsperpage: 'Rows per page:',
            back_button: 'Back',
            orderinfo_clientinfo: 'Client information',
            orderinfo_fullname: 'Full name',
            orderinfo_email: 'E-mail address',
            orderinfo_phone: 'Phone number',
            orderinfo_city: 'City',
            orderinfo_address: 'Address',
            orderinfo_idnumber: 'ID card number',
            orderinfo_dateofexpiry: 'ID card expiry date',
            orderinfo_requestinfo: 'Request information',
            orderinfo_requestpaymenttype: 'Payment provider',
            consents_newtype: 'New document type',
            consents_activefilter: 'Show inactive',
            consents_doctype: 'Document type',
            consents_updated: 'Updated',
            consents_enabled: 'Enabled',
            consents_required: 'Required',
            edit_button: 'EDIT',
            details_button: 'DETAILS',
            lastedited: 'Last edited:',
            active: 'Active',
            activate: 'Activate',
            required: 'Required',
            addnewdoc: 'Add new document',
            dragndrop_text: 'Drag and drop here or click',
            dragndrop_tooLargeFile: 'File size is too large',
            dragndrop_notSupportedAttachment: 'Only PDF file type is supported',
            dragndrop_selectFile: 'Select file',
            dragndrop_noFilesAddedPrimary: 'No files added',
            dragndrop_noFilesAddedSecondary: 'You can add a file by dragging it over the field above or by clicking on the field',
            please_try_again: 'Please try again',
            send_button: 'Send',
            sending: 'Sending',
            last_change: 'Last change',
            consents_addgroup: 'Add new document group',
            consents_uploaddoc: 'Upload new document',
            name: 'Name',
            processes_erroronly: 'Show errors only',
            date: 'Date',
            processes_personaldata: 'Personal data',
            processes_docnumber: 'Document number',
            processes_networkdata: 'Network data',
            processes_ipaddress: 'IP address',
            processes_useragent: 'User agent',
            processes_dateofbirth: 'Date of birth',
            processes_sex: 'Sex',
            processes_nationalitycode: 'Nationality code',
            processes_dateofissue: 'Date of issue',
            processes_dateofexpiry: 'Date of expiry',
            processes_issuingcountrycode: 'Issuing country code',
            tos_acceptedconsents: 'Accepted consents:',
            pdfpage: 'from',
            psd2_currency: 'Currency',
            psd2_displayname: 'Display name',
            psd2_cashacctype: 'Cash account type',
            psd2_usage: 'Usage',
            psd2_ownername: 'Owner name',
            psd2_balancetype: 'Balance type',
            psd2_amount: 'Amount',
            psd2_creditlimit: 'Credit limit included',
            psd2_transactionid: 'Transaction ID',
            psd2_entryreference: 'Entry reference',
            psd2_bookingdate: 'Booking date',
            psd2_valuedate: 'Value date',
            psd2_transactionamount: 'Transaction amount',
            psd2_creditorname: 'Creditor name',
            psd2_creditoriban: 'Creditor IBAN',
            psd2_ultimatecreditor: 'Ultimate creditor',
            psd2_debtorname: 'Debtor name',
            psd2_debtoriban: 'Debtor IBAN',
            psd2_ultimatedebtor: 'Ultimate debtor',
            psd2_transactioninfo: 'Transaction info',
            psd2_additionalinfo: 'Additional transaction info',
            psd2_purposecode: 'Purpose code',
            psd2_sourcecurrency: 'Source currency',
            psd2_targetcurrency: 'Target currency',
            psd2_exchangerate: 'Exchange rate',
            agent_face_match: 'Agent face match',
            face_match: 'Face match',
            video_check_result: 'Video check result',
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'hr',
    fallbackLng: 'hr',
    interpolation: {
        escapeValue: false,
    },
});
