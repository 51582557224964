import { combineReducers } from '@reduxjs/toolkit';
import { reducer as authReducer } from '../slices/auth';
import { reducer as processMonitorReducer } from '../slices/processMonitor';
import { reducer as dataControllerReducer } from '../slices/dataController';
import { reducer as tosControllerReducer } from '../slices/tosController';
import { reducer as identyumControllerReducer } from '../slices/identyumController';

const rootReducer = combineReducers({
    auth: authReducer,
    processMonitor: processMonitorReducer,
    dataController: dataControllerReducer,
    tosController: tosControllerReducer,
    identyumController: identyumControllerReducer,
});

export default rootReducer;
