import { useEffect, useState } from 'react';
import ErrorSnackbar from '../errorSnackbar';
import { CircularProgress, Grid, IconButton, Rating, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearError } from '../../slices/processMonitor';
import styles from './style.module.scss';
import { getIdentificationReliabilityConfig, putIdentificationReliabilityConfig } from '../../slices/identyumController';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { IdentificationReliabilityConfig } from 'kent-identyum-service-api';
import { Roles } from '../../lib/constants/roles';
import { useAuth } from '../../hooks/useAuth';

interface Props {}

const Identification: React.FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { userRoles } = useAuth();
    const { agentFaceMatch, error, faceMatch, isLoading, isLoadingPut, videoCheckResult } = useAppSelector((state) => state.identyumController);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [formState, setFormState] = useState<IdentificationReliabilityConfig>({
        agentFaceMatch: agentFaceMatch,
        faceMatch: faceMatch,
        videoCheckResult: videoCheckResult,
    });

    useEffect(() => {
        dispatch(getIdentificationReliabilityConfig());
    }, []);

    useEffect(() => {
        if (error) {
            setTimeout(() => dispatch(clearError()), 4000);
        }
    }, [error]);

    const handleSave = () => {
        dispatch(putIdentificationReliabilityConfig(formState));
    };

    return (
        <div className={styles.root}>
            {error && <ErrorSnackbar message={error} />}
            <Grid container direction="row" alignItems="center" justifyContent="space-around" className={styles.flexWrap}>
                {isLoading ? (
                    <div className="loaderMainDiv">
                        <div className="loaderBgDiv" />
                        <div className="loaderInnerDiv">
                            <div className="imgSize" style={{ position: 'relative' }}>
                                <CircularProgress color="secondary" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {userRoles.includes(Roles.identificationReliabilityConfigWrite) && (
                            <>
                                <IconButton disabled={isLoadingPut} aria-label="edit" onClick={() => setReadOnly(!readOnly)} className={styles.edit}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    disabled={
                                        (agentFaceMatch === formState.agentFaceMatch &&
                                            faceMatch === formState.faceMatch &&
                                            videoCheckResult === formState.videoCheckResult) ||
                                        isLoadingPut
                                    }
                                    aria-label="edit"
                                    onClick={handleSave}
                                    className={styles.save}>
                                    <SaveIcon />
                                </IconButton>
                            </>
                        )}
                        <Grid container direction="column" alignItems="center" justifyContent="space-around" width={'fit-content'}>
                            <Typography component="legend">{t('agent_face_match')}</Typography>
                            <Rating
                                name="simple-controlled"
                                value={formState.agentFaceMatch}
                                precision={1}
                                max={5}
                                readOnly={readOnly}
                                disabled={isLoadingPut}
                                onChange={(_event, newValue) => {
                                    if (newValue && newValue < 2) {
                                        return;
                                    } else setFormState({ ...formState, agentFaceMatch: newValue as any });
                                }}
                            />
                        </Grid>
                        <Grid container direction="column" alignItems="center" justifyContent="space-around" width={'fit-content'}>
                            <Typography component="legend">{t('face_match')}</Typography>
                            <Rating
                                name="simple-controlled"
                                value={formState.faceMatch === 0 ? null : formState.faceMatch}
                                precision={1}
                                max={3}
                                readOnly={readOnly}
                                disabled={isLoadingPut}
                                onChange={(_event, newValue) => {
                                    if (newValue === null) {
                                        setFormState({ ...formState, faceMatch: 0 });
                                    }
                                    setFormState({ ...formState, faceMatch: newValue as any });
                                }}
                            />
                        </Grid>
                        <Grid container direction="column" alignItems="center" justifyContent="space-around" width={'fit-content'}>
                            <Typography component="legend">{t('video_check_result')}</Typography>
                            <Rating
                                name="simple-controlled"
                                value={formState.videoCheckResult}
                                precision={1}
                                max={3}
                                readOnly={readOnly}
                                disabled={isLoadingPut}
                                onChange={(_event, newValue) => {
                                    if (newValue === null) {
                                        return;
                                    } else setFormState({ ...formState, videoCheckResult: newValue as any });
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default Identification;
