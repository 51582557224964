import { KentTosPublicControllerApi, Configuration, DocumentContent, KentTosControllerApi, CreateGroupRequest, Group } from 'kent-tos-service-api';
import Cookies from 'universal-cookie/es6';

const cookies = new Cookies();
const token = cookies.get('access_token_obj');

export const KentTosPublicControllerClient = (): KentTosPublicControllerApi => {
    const configuration: Configuration = new Configuration({ accessToken: token.accessToken, basePath: process.env.REACT_APP_KENT_TOS_URL });
    return new KentTosPublicControllerApi(configuration);
};

export const KentTosControllerClient = (): KentTosControllerApi => {
    const configuration: Configuration = new Configuration({ accessToken: token.accessToken, basePath: process.env.REACT_APP_KENT_TOS_URL });
    return new KentTosControllerApi(configuration);
};

export const getConsentContent = async (consentId: string): Promise<DocumentContent> => {
    return KentTosPublicControllerClient()
        .getConsentContent(consentId)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const createGroup = async (createGroupRequest: CreateGroupRequest): Promise<Group> => {
    return KentTosControllerClient()
        .createGroup(createGroupRequest)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
