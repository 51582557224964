import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Replay } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { TokenHelper } from '../../utils/token-helper';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { getLandingPageByRole } from '../../helpers/helpers';
import { useAuth } from '../../hooks/useAuth';
import axios, { AxiosInstance } from 'axios';
import SplashScreen from './SplashScreen';
import { CircularProgress } from '@material-ui/core';

interface AccessTokenResponse {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
}

export interface ICustomJwtPayload extends JwtPayload {
    family_name: string;
    given_name: string;
    preferred_username: string;
    realm_access: {
        roles: string[]
    }
}

export const baseAxiosInstance: AxiosInstance = axios.create();

export const AuthorizationCodeCallbackPage: FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation();
    const [ showLoader, setShowLoader ] = useState(true);
    const [ error, setError ] = useState<string>('');
    const [ isProcessing, setIsProcessing ] = useState<boolean>(false);
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.has('code') ? searchParams.get('code') as string : '';

    useEffect(() => {
        const errorCode = searchParams.get('error');
        if (errorCode) {
            const description = searchParams.get('error_description');
            setError(description ?? errorCode);
            return;
        }

        setShowLoader(false);
        setIsProcessing(true);
        baseAxiosInstance
            .post(
                `${ process.env.REACT_APP_ACCESS_URL }`,
                new URLSearchParams({
                    client_id: process.env.REACT_APP_CLIENT_ID ?? '',
                    code: code,
                    grant_type: 'authorization_code',
                    redirect_uri: process.env.REACT_APP_REDIRECT_URL + '/login',
                    code_verifier: localStorage.getItem('codeVerifier') ?? '',
                }),
            )
            .then((response) => {
                handleSuccess(response.data as AccessTokenResponse).then(() => {
                    setIsProcessing(false);
                });
            })
            .catch(() => {
                handleError();
                setIsProcessing(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSuccess = async (accessToken: AccessTokenResponse) => {
        console.log('**** handleSuccess ****', accessToken);
        const decodedToken: ICustomJwtPayload = jwtDecode(accessToken.access_token);
        const token: any = {
            accessToken: accessToken.access_token,
            expires: new Date(Date.now() + accessToken.expires_in * 1000),
            expiresIn: accessToken.expires_in,
            refreshExpires: new Date(Date.now() + accessToken.refresh_expires_in * 1000),
            refreshExpiresIn: accessToken.refresh_expires_in,
            refreshToken: accessToken.refresh_token,
        };

        const username: string = TokenHelper.getUsernameFromToken(decodedToken);
        const roles: string[] = TokenHelper.getUserRolesFromToken(decodedToken);

        await auth.handleLoginSuccess(token, username, roles);

        const redirectLink = localStorage.getItem('redirectLink') as string;
        if (redirectLink) {
            return navigate(redirectLink, { replace: true });
        }

        navigate(getLandingPageByRole(roles), { replace: true });
    };

    const handleError = () => {
        setShowLoader(false);
        const searchParams = new URLSearchParams(window.location.search);
        const error = searchParams.get('error') ?? 'login_error';
        setError(error);
    };

    const handleTryAgain = () => {
        navigate('/login');
    };

    function getRenderComponent() {
        return (
            <div>
                { isProcessing && <SplashScreen /> }
                { error && (
                    <>
                        <p style={ { color: 'red', fontSize: '1.25rem' } }>
                            An error occurred: { error }
                        </p>
                        <Grid container
                              direction="row"
                              alignItems="center"
                              justifyContent="center">
                            <Button variant="outlined"
                                    color="primary"
                                    style={ {
                                        alignItems: 'center',
                                        borderWidth: '3px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: 'auto',
                                        marginTop: 'auto',
                                        zIndex: 100,
                                    } }
                                    onClick={ handleTryAgain }>
                                { t('please_try_again') }
                                <Replay style={ { marginLeft: '0.5rem' } } />
                            </Button>
                        </Grid>
                    </>
                ) }
            </div>
        );
    }

    return (
        <Grid container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={ {
                  height: '100vh',
                  padding: '3rem',
                  width: '100%',
              } }>
            { showLoader && (
                <div className='loaderMainDiv'>
                    <div className='loaderBgDiv' />
                    <div className='loaderInnerDiv'>
                        <div className='imgSize' style={ { position: 'relative' } }>
                            <CircularProgress color="secondary" />
                        </div>
                    </div>
                </div>
            ) }
            { getRenderComponent() }
        </Grid>
    );
};
