import { ChangeEvent, useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import EditConsentGroup from './EditConsentGroup';
import AddConsentGroup from './AddConsentGroup';
import { IConsentGroup } from '../../interfaces/CustomInterfaces';
import CustomPagination from '../TableOverrides/CustomPagination';
import { croLocale } from '../../helpers/dataGridLocales';
import { formatDate } from '../../helpers/helpers';
import 'date-fns';
import { Button, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { Info, CheckBox, CheckBoxOutlineBlank, Add, Edit } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { Roles } from '../../lib/constants/roles';
import styles from './style.module.scss';
import { ErrorModelProvider } from '../../errorHandling/ErrorModelProvider';

interface IConsentOverviewProps {}

const ConsentOverview: React.FunctionComponent<IConsentOverviewProps> = () => {
    const { t, i18n } = useTranslation();
    const { token, userRoles } = useAuth();
    const [tableData, setTableData] = useState<IConsentGroup[]>();
    const [editId, setEditId] = useState<IConsentGroup | null>(null);
    const [addNew, setAddNew] = useState<boolean>(false);
    const [filters, setFilters] = useState<boolean>(true);
    const [pageSize, setPageSize] = useState<number>(10);

    useEffect(() => {
        const handleMount = async () => {
            getListData();
        };

        handleMount();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getListData = async () => {
        axios
            .get(`${process.env.REACT_APP_KENT_TOS_URL}/api/v1/groups`, {
                headers: { Authorization: `Bearer ${token?.accessToken}`, 'Content-Type': 'application/json' },
            })
            .then((response) => {
                setTableData(response.data.groups);
            })
            .catch((err) => {
                let error = ErrorModelProvider.provide(err as AxiosError);
                console.error('error', err, error);
            });
    };

    const handleAddNewGroupFinish = (data?: any) => {
        setAddNew(false);
        if (data) {
            // TODO - dont request the updated list, update list list
            getListData();
            // let newTableData: Array<any> = tableData as Array<any>;
            // newTableData.push(data);
            // setTableData(newTableData as []);
        }
    };

    const handleCancel = (dirty?: boolean) => {
        setEditId(null);
        dirty && getListData();
    };

    const handleAddNewGroup = () => {
        setAddNew(true);
    };

    const handleFilterChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setFilters(ev.target.checked);
    };

    const handleFilter = () => {
        if (filters && tableData) return tableData;
        else if (!filters && tableData) return tableData.filter((el: IConsentGroup) => el.enabled);
        return [...Array(10)].map((_, i) => {
            return { id: i.toString(), amount: '', firstName: '', lastName: '', oib: '', status: true, merchantName: '' };
        });
    };

    const handlePageSizeChange = (num: number) => {
        setPageSize(num);
    };

    const typeWidth = 250;
    const activeWidth = 180;
    const enabledWidth = 110;
    const requiredWidth = 120;
    const editWidth = 120;
    const headers = {
        type: `${t('consents_doctype')}`,
        updated: `${t('consents_updated')}`,
        enabled: `${t('consents_enabled')}`,
        required: `${t('consents_required')}`,
    };
    const columns: GridColDef[] = [
        {
            field: 'label',
            headerName: headers.type,
            width: typeWidth,
            filterable: false,
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={20} animation="wave" />;
            },
        },
        {
            field: 'updatedAt',
            headerName: headers.updated,
            width: activeWidth,
            valueGetter: (params) => {
                if (tableData) return new Date(params.row.createdAt);
            },
            valueFormatter: (params) => {
                if (tableData) return formatDate(new Date(params.value));
            },
            renderCell: () => {
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={20} animation="wave" />;
            },
        },
        {
            field: 'enabled',
            headerName: headers.enabled,
            width: enabledWidth,
            renderCell: (params: GridRenderCellParams) => {
                if (!tableData)
                    return (
                        <Grid container direction="column" alignContent="center">
                            <Skeleton variant="rectangular" width={20} height={20} animation="wave" />
                        </Grid>
                    );
                return (
                    <Grid container direction="column" alignContent="center">
                        {params.row.enabled ? (
                            <CheckBox className={styles.disabledPrimary} color="secondary" />
                        ) : (
                            <CheckBoxOutlineBlank className={styles.disabledPrimary} color="secondary" />
                        )}
                    </Grid>
                );
            },
        },
        {
            field: 'required',
            headerName: headers.required,
            width: requiredWidth,
            renderCell: (params) => {
                if (!tableData)
                    return (
                        <Grid container direction="column" alignContent="center">
                            <Skeleton variant="rectangular" width={20} height={20} animation="wave" />
                        </Grid>
                    );
                return (
                    <Grid container direction="column" alignContent="center">
                        {params.row.required ? (
                            <CheckBox className={styles.disabledPrimary} color="secondary" />
                        ) : (
                            <CheckBoxOutlineBlank className={styles.disabledPrimary} color="secondary" />
                        )}
                    </Grid>
                );
            },
        },
        {
            field: '',
            headerName: ' ',
            width: editWidth,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const onClick = () => {
                    setEditId(params.row as IConsentGroup);
                };
                if (!tableData) return <Skeleton variant="text" width={'100%'} height={30} animation="wave" />;
                return (
                    <strong>
                        {userRoles.includes(Roles.termsWrite) ? (
                            <Button color="secondary" onClick={onClick}>
                                <Edit style={{ marginRight: '5px' }} />
                                {t('edit_button')}
                            </Button>
                        ) : (
                            <Button color="secondary" onClick={onClick}>
                                <Info style={{ marginRight: '5px' }} />
                                {t('details_button')}
                            </Button>
                        )}
                    </strong>
                );
            },
        },
    ];

    return (
        <div className={styles.root}>
            {editId === null && !addNew && (
                <Grid container direction="column" className={styles.mainContainer}>
                    {userRoles.includes(Roles.termsWrite) && (
                        <Button onClick={handleAddNewGroup} variant="contained" startIcon={<Add />} style={{ marginBottom: '1rem', marginRight: 'auto' }}>
                            {t('consents_newtype')}
                        </Button>
                    )}
                    <Grid container direction="row" alignItems="center" justifyContent="flex-start" style={{ marginBottom: '2rem' }}>
                        <FormControlLabel
                            control={<Checkbox checked={filters} onChange={handleFilterChange} name="checkedB" color="primary" />}
                            label={t('consents_activefilter')}
                        />
                    </Grid>
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            autoHeight
                            disableSelectionOnClick
                            disableColumnMenu
                            disableColumnFilter
                            disableDensitySelector
                            disableColumnSelector
                            pageSize={pageSize}
                            onPageSizeChange={handlePageSizeChange}
                            rowsPerPageOptions={[5, 10, 20, 50]}
                            pagination
                            rows={handleFilter()}
                            columns={columns}
                            localeText={i18n.language === 'hr' ? croLocale : undefined}
                            components={{
                                Pagination: CustomPagination,
                            }}
                        />
                    </div>
                </Grid>
            )}
            {editId !== null && !addNew && <EditConsentGroup group={editId} back={handleCancel} />}
            {addNew && <AddConsentGroup back={handleAddNewGroupFinish} />}
        </div>
    );
};

export default ConsentOverview;
