export enum Roles {
    offline = 'offline_access',
    admin = 'admin',
    requestView = 'request_view',
    termsWrite = 'terms_write',
    termsRead = 'terms_view',
    umaAuth = 'uma_authorization',
    processMonitor = 'process_monitor',
    identificationReliabilityConfigRead = 'identification_reliability_config_read',
    identificationReliabilityConfigWrite = 'identification_reliability_config_write',
}
