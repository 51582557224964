import { KentIdentyumControllerApi, Configuration, IdentificationReliabilityConfig } from 'kent-identyum-service-api';
import Cookies from 'universal-cookie/es6';

const cookies = new Cookies();
const token = cookies.get('access_token_obj');

export const KentIdentyumControllerClient = (): KentIdentyumControllerApi => {
    const configuration: Configuration = new Configuration({ accessToken: token.accessToken, basePath: process.env.REACT_APP_IDENTYUM_SERVICE });
    return new KentIdentyumControllerApi(configuration);
};

export const getIdentificationReliabilityConfig = async (): Promise<IdentificationReliabilityConfig> => {
    return KentIdentyumControllerClient()
        .getConfig()
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};

export const putIdentificationReliabilityConfig = async (
    identificationReliabilityConfig: IdentificationReliabilityConfig
): Promise<IdentificationReliabilityConfig> => {
    return KentIdentyumControllerClient()
        .updateConfig(identificationReliabilityConfig)
        .then((response) => response.data)
        .catch((error) => {
            throw error;
        });
};
